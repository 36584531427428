import Link from 'next/link';
import React, { AnchorHTMLAttributes, ReactNode } from 'react';
import { StylishBoxStyles } from '../StylishBox/types';
import { useTheme } from '../theme';
import getLinkStyles from './getLinkStyles';
import styled from '@emotion/styled';
import { getResultEmotionStyles } from '../StylishBox/helpers/getResultEmotionStyles';
import { Theme } from '../theme/types';
import isPropValid from '@emotion/is-prop-valid';

export type StylishLinkVariant = 'accented' | 'notAccented';

const StyledNextLink = styled(Link, {
  shouldForwardProp: prop => isPropValid(prop)
    && prop !== 'variant'
    && prop !== 'theme'
    && prop !== 'sbs',
})((props: StylishLinkProps & {
  variant: StylishLinkVariant;
  theme: Theme;
}) => {
  const linkStyles = getLinkStyles(props.variant, props.theme);

  return [
    ...getResultEmotionStyles(props.sbs),
    ...linkStyles,
  ];
});

const StyledTagA = styled('a', {
  shouldForwardProp: prop => isPropValid(prop)
    && prop !== 'variant'
    && prop !== 'theme'
    && prop !== 'sbs',
})((props: StylishLinkProps & {
  variant: StylishLinkVariant;
  theme: Theme;
}) => {
  const linkStyles = getLinkStyles(props.variant, props.theme);

  return [
    ...getResultEmotionStyles(props.sbs),
    ...linkStyles,
  ];
});

interface StylishLinkProps {
  children: ReactNode;
  href: string;
  /** @default notAccented */
  variant?: StylishLinkVariant;
  /** @default false */
  innerLink?: boolean;
  className?: string;
  sbs?: StylishBoxStyles;
  dataMarker?: string;
  /** @default false */
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  rel?: AnchorHTMLAttributes<HTMLAnchorElement>['rel'];
  onClick?(e: React.MouseEvent): void;
}

const StylishLink = (props: StylishLinkProps) => {
  const {
    children,
    href,
    sbs,
    className: classNameProps,
    variant = 'notAccented',
    target,
    innerLink = false,
    rel,
    dataMarker,
    onClick,
  } = props;
  const theme = useTheme();
  if (innerLink) {
    return (
      <StyledNextLink
        href={href}
        className={classNameProps}
        target={target}
        rel={rel}
        data-marker={dataMarker}
        onClick={onClick}
        theme={theme}
        variant={variant}
        sbs={sbs}
      >
        {children}
      </StyledNextLink>
    );
  }

  return (
    <StyledTagA
      href={href}
      className={classNameProps}
      target={target}
      rel={rel}
      data-marker={dataMarker}
      onClick={onClick}
      theme={theme}
      variant={variant}
      sbs={sbs}
    >
      {children}
    </StyledTagA>
  );
};

export default StylishLink;
