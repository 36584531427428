import { Theme } from '../theme/types';
import { ButtonVariant } from './Button';
import { CSSInterpolation } from '@emotion/serialize';

const baseStyles: CSSInterpolation = {
  /* && - used here to increase the priority of the selector
    so that these styles cannot be overwritten using the sbs object */
  '&&': {
    outline: 'none',
    border: 'none',
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all ease-in-out .15s',
    textDecoration: 'none',
    cursor: 'pointer',
    appearance: 'none',
    '&[aria-disabled = true], &:disabled': {
      cursor: 'default',
    },
  },
};

const getStylesOfVariant = (variant: ButtonVariant, theme: Theme): CSSInterpolation => {
  const buttonStyles = theme.button[variant];

  return {
    /* && - used here to increase the priority of the selector
      so that these styles cannot be overwritten using the sbs object */
    '&&': {
      height: buttonStyles.height,
      fontFamily: theme.base.fontFamily[buttonStyles.fontFamily],
      fontSize: buttonStyles.fontSize,
      fontWeight: buttonStyles.fontWeight,
      color: buttonStyles.color,
      backgroundColor: buttonStyles.bgColor,
      border: `${buttonStyles.bdWidth} solid`,
      borderColor: buttonStyles.bdColor,
      borderRadius: buttonStyles.bdRadius,
      '&:hover': {
        color: buttonStyles.colorHover,
        backgroundColor: buttonStyles.bgColorHover,
        borderColor: buttonStyles.bdColorHover,
      },
      '&:active': {
        color: buttonStyles.colorActive,
        backgroundColor: buttonStyles.bgColorActive,
        borderColor: buttonStyles.bdColorActive,
      },
      '&[aria-disabled = true], &:disabled': {
        color: buttonStyles.colorDisabled,
        backgroundColor: buttonStyles.bgColorDisabled,
        borderColor: buttonStyles.bdColorDisabled,
      },
    },
  };
};

const getStyles = (variant: ButtonVariant, theme: Theme) => {
  return [
    baseStyles,
    getStylesOfVariant(variant, theme),
  ];
};

export default getStyles;
