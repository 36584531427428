import Cookies from 'js-cookie';
import { ParsedUrlQuery } from 'querystring';

export const updateAffiliateProgramsCookie = (
  query: ParsedUrlQuery,
  host?: string,
) => {
  if (host) {
    const topHost = host.split(':')[0].split('.').slice(-2).join('.');

    if (query?.admitad_uid) {
      setAdmitadCookie(query.admitad_uid as string, topHost);
    }

    if (query?.aff_sub) {
      setSalesDoublerCookie(query.aff_sub as string, topHost);
    }
  }
};

const setAdmitadCookie = (value: string, host: string) => {
  Cookies.set(
    'admitad_uid',
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};

const setSalesDoublerCookie = (value: string, host: string) => {
  Cookies.set(
    'sd_uid',
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};
