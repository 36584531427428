import Image from "next/image";

const FooterUpButton = () => (
  <span className="FooterUpButton">
    <Image
      width={14}
      height={18}
      src="/static/images/up-arrow.svg"
      alt="Up arrow"
      onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}
    />
    <style jsx>{`
      .FooterUpButton {
        cursor: pointer;
      }
    `}</style>
  </span>
);

export default FooterUpButton;
