import Image from "next/image";
import Link from "next/link";
import { LOGO_URL } from "src/data/constants";
import useLang from "src/services/language/useLang";

interface Props {
  size?: 'medium' | 'large';
}

const Logo = (props: Props) => {
  const language = useLang();
  const { size = 'medium' } = props;

  const sizeParams = {
    medium: {
      width: 115,
      height: 32,
    },
    large: {
      width: 172,
      height: 48,
    },
  };

  return (
    <Link href={`/${language}/`}>
      <span className="Logo" data-marker="Logo">
        <Image
          {...sizeParams[size]}
          src={LOGO_URL}
          alt="Zakaz logo"
        />
      </span>
    </Link>
  );
};

export default Logo;
