import cn from 'classnames';
import { Fragment } from 'react';
import { NEW_YEAR_AVAILABLE } from 'src/data/constants';
import Button from 'src/ui-kit/Button';
import { useLocalize } from '../../../../services/localize/useLocalize';
import useThemeOld from '../../../../services/theme/useThemeOld';
import IconFont from '../../../../ui-kit/IconFont';
import { Typography } from '../../../../ui-kit/Typography';
import useAddressManagementPreparer from '../../hooks/useAddressManagementPreparer/useAddressManagementPreparer';
import getStyles from './AddressManagementBar.styles';
import AddressManagementBarSkeleton from './AddressManagementBarSkeleton';

export interface AddressManagementBarProps {
  size: 'small' | 'medium' | 'large';
  withIconAnimation?: boolean;
  onAmButtonClick(): void;
}

const AddressManagementBar = (props: AddressManagementBarProps) => {
  const {
    size,
    withIconAnimation = false,
    onAmButtonClick,
  } = props;
  const {
    userDelivery,
    amBarProps: {
      skeletonIsDisplayed,
      deliveryTypeText,
      deliveryAddressText,
    },
  } = useAddressManagementPreparer();
  const theme = useThemeOld();
  const localize = useLocalize();
  const styles = getStyles(theme);

  if (skeletonIsDisplayed) {
    return <AddressManagementBarSkeleton />;
  }

  const buttonIsDisplayedAsIcon = size === 'small';

  return (
    <div
      className={ cn(
        'AddressManagementBar', {
          'AddressManagementBar_large': size === 'large',
          'AddressManagementBar_medium': size === 'medium',
          'AddressManagementBar_small': size === 'small',
        },
      ) }
      data-marker='Address Management Bar'
      onClick={onAmButtonClick}
    >
      <span
        className={cn('AddressManagementBar__iconDelivery', {
          'AddressManagementBar__iconDelivery_withAnimation': withIconAnimation && !userDelivery,
          'AddressManagementBar__iconNY': NEW_YEAR_AVAILABLE,
        })}
      >
        <IconFont name={NEW_YEAR_AVAILABLE ? 'christmas-tree' : 'pin'} />
      </span>

      <div className='AddressManagementBar__delivery'>
        {
          !userDelivery ? (
            <Fragment>
              <Typography element='span' color='primary'>
                {localize('am_bar_enter_delivery_address_1')}
              </Typography>
              <Typography element='span'>
                {localize('am_bar_enter_delivery_address_2')}
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              <Typography element='span' variant='text3'>
                { deliveryTypeText }
              </Typography>

              { !!deliveryAddressText && (
                <Typography element='span'>
                  { ': ' }{ deliveryAddressText }
                </Typography>
              )}
            </Fragment>
          )
        }
      </div>

      {userDelivery && (
        buttonIsDisplayedAsIcon ? (
          <IconFont
            name='pencil'
            color='primary'
            size='16px'
            dataMarker='Address'
          />
        ) : (
          <div className='AddressManagementBar__button'>
            <Button
              variant={'ghost'}
              onClick={onAmButtonClick}
              dataMarker='Address'
            >{localize('am_bar_edit_address')}</Button>
          </div>
        ))
      }

      <style jsx>{ styles }</style>
    </div>
  );
};

export default AddressManagementBar;
