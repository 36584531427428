import { CoordsNumbers, CoordsStrings } from "src/services/userDelivery/types";

export function coordsToString(coords: CoordsNumbers): CoordsStrings {
  return {
    lat: '' + coords.lat,
    lng: '' + coords.lng,
  };
}

export function coordsToNumbers(coords: CoordsStrings): CoordsNumbers {
  return {
    lat: Number(coords.lat),
    lng: Number(coords.lng),
  };
}

export function isEqualCoords(
  coords_1: CoordsNumbers,
  coords_2: CoordsNumbers,
): boolean {
  return coords_1.lat === coords_2.lat && coords_1.lng === coords_2.lng;
}

export const getStringFromCoords = (coords: CoordsNumbers | CoordsStrings) => {
  return `${coords.lat},${coords.lng}`;
};
