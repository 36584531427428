import { useEffect, useState } from 'react';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { UserDeliveryType } from '../../modules/delivery/types';
import getDeliveryFromServerCart from './helpers/getDeliveryFromServerCart';


export const USER_DELIVERY_KEY = 'userDelivery';

export interface UserDeliveryService {
  userDelivery: UserDeliveryType;
  userDeliveryIsReady: boolean;
  updateUserDelivery(delivery: UserDeliveryType): void;
}

const useUserDeliveryService = (language: string, isLoggedIn: boolean): UserDeliveryService => {
  const [userDeliveryLocalStorage, setUserDeliveryLocalStorage] = useLocalStorage<UserDeliveryType>(USER_DELIVERY_KEY, null);
  const [userDeliverySessionStorage, setUserDeliverySessionStorage] = useSessionStorage<UserDeliveryType>(USER_DELIVERY_KEY, null);

  const [userDeliveryIsReady, setUserDeliveryIsReady] = useState(false);
  const userDelivery = userDeliverySessionStorage === null ? userDeliveryLocalStorage : userDeliverySessionStorage;

  useEffect(() => {
    (async () => {
      if (isLoggedIn && userDeliverySessionStorage === null) {
        const newUserDelivery = await getDeliveryFromServerCart(language);

        setUserDeliverySessionStorage(newUserDelivery);
      }

      setUserDeliveryIsReady(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const updateUserDelivery = (delivery: UserDeliveryType) => {
    if (isLoggedIn && userDeliverySessionStorage !== null) {
      setUserDeliverySessionStorage(delivery);
    } else {
      setUserDeliveryLocalStorage(delivery);
    }
  };

  return {
    userDelivery,
    userDeliveryIsReady,
    updateUserDelivery,
  };
};

export default useUserDeliveryService;
