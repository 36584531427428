import cn from 'classnames';
import Image from 'next/image';
import React from 'react';
import useThemeOld from '../../../../services/theme/useThemeOld';
import { Typography } from '../../../../ui-kit/Typography';
import { RetailInfo } from '../../types';
import CartCountIcon from '../CartCountIcon/CartCountIcon';
import RetailTimeWindow from '../RetailTimeWindow/RetailTimeWindow';
import getStyles from './RetailInfoCard.styles';

interface RetailInfoCardProps {
  size: 'small' | 'medium' | 'large';
  retailInfo: RetailInfo;
  isAvailable?: boolean;
  onClick(event: React.MouseEvent): void;
  dataStatus: 'active' | 'inactive';
}

const RetailInfoCard = (props: RetailInfoCardProps) => {
  const {
    retailInfo: {
      logoUrl,
      linkUrl,
      id,
      chainName,
      timeWindowText,
      timeWindowHasError,
      timeWindowTooltip,
      cartCount,
      promoText,
    },
    size,
    dataStatus,
    isAvailable = true,
    onClick,
  } = props;

  const timeWindowDataMarker = timeWindowHasError ? 'Time slot Error' : 'Time slot';

  const theme = useThemeOld();
  const styles = getStyles(theme);
  const tooltipId = `${id}_tooltip`;

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href={linkUrl}
      onClick={onClick}
      className={cn('RetailInfoCard', {
        'RetailInfoCard_large': size === 'large',
        'RetailInfoCard_medium': size === 'medium',
        'RetailInfoCard_small': size === 'small',
        'RetailInfoCard_unavailable': !isAvailable,
      })}
      data-marker={chainName}
      data-status={dataStatus}
    >
      { !!cartCount &&
        <div className="RetailInfoCard__cart">
          <CartCountIcon
            count={cartCount}
          />
        </div>
      }

      <div className="RetailInfoCard__logo">
        <Image
          src={logoUrl}
          alt={chainName}
          width={130}
          height={36}
        />
       </div>

      { timeWindowText &&
        <div className="RetailInfoCard__timeWindow">
          <RetailTimeWindow
            timeWindowText={timeWindowText}
            dataMarkerValue={timeWindowDataMarker}
            id={tooltipId}
            timeWindowTooltip={timeWindowTooltip}
          />
        </div>
      }

      { !!promoText &&
        <div className="RetailInfoCard__promo">
          <Typography variant='text6' color='primary'>
            {promoText}
          </Typography>
        </div>
      }
      <style jsx>{styles}</style>
    </a>
  );
};

export default RetailInfoCard;
