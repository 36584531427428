
import Cookies from 'js-cookie';
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { updateAffiliateProgramsCookie } from "src/utils/affiliatePrograms";
import { sendDimensionSet } from "src/utils/enhancedEcommerce";

const GaClientWatcher = () => {
  useEffect(() => {
    const gaCookie = Cookies.get('_ga');
      const clientId = gaCookie && gaCookie.slice(6);
      sendDimensionSet(clientId);
  }, []);

  return null;
};

interface Props {
  host?: string;
}

const AffiliateProgramsCookieWatcher = ({ host }: Props) => {
  const { query } = useRouter();

  useEffect(() => {
    updateAffiliateProgramsCookie(query, host);
  }, [host, query]);

  return null;
};

const AppCookiesWatcher = (props: Props) => {
  return (
    <Fragment>
      <AffiliateProgramsCookieWatcher host={props.host} />
      <GaClientWatcher />
    </Fragment>
  );
};

export default AppCookiesWatcher;
