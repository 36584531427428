import { fetcher } from "src/api/fetch";
import { Store } from "src/api/types";
import useLang from "src/services/language/useLang";
import useUserDelivery from "src/services/userDelivery/useUserDelivery";
import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import useSWR from "swr";
import { getStoresRequestStringFromUserDelivery } from "../helpers/getStoresRequestStringFromUserDelivery";

const emptyArray: Store[] = [];

const useStoresByUserDelivery = () => {
  const language = useLang();
  const {
    userDelivery,
  } = useUserDelivery();

  const swrFetcher = async () => {
    const url = `/stores/${getStoresRequestStringFromUserDelivery(userDelivery)}`;

    const res = await fetcher<Store[]>(url, {
      language,
    });

    return getDataFromResponse(res, []);
  };

  const { data, error } = useSWR<Store[]>(
    userDelivery
      ? [userDelivery, language]
      : null,
    swrFetcher);

  return {
    stores: data || emptyArray,
    storesAreFetching: userDelivery ? !data && !error : false,
  };
};

export default useStoresByUserDelivery;
