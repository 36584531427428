import { fetcher } from "src/api/fetch";
import { ScheduleDeliveryItem } from "src/api/types";
import { Delivery } from "src/modules/delivery/types";
import { getDataFromResponse } from "src/utils/api/getDataFromResponse";

interface Params {
  storeIds: string;
  deliveryType?: Delivery['type'];
  ean: string;
}

export const requestPostScheduleDelivery = async (params: Params) => {
  const {
    storeIds,
    deliveryType,
    ean,
  } = params;
  const response = await fetcher<ScheduleDeliveryItem[]>('/delivery_schedule/', {
    method: 'POST',
    headers: {
      'x-store-ids': storeIds,
    },
    body: JSON.stringify({
      delivery_method: deliveryType,
      slot_types: [
        "open",
      ],
      cart_items: [
        {
          ean,
          amount: 1,
        },
      ],
      days: '1',
    }),
  });

  return getDataFromResponse(response, []);
};
