import { memo } from 'react';

interface Props {
  gtmId: string;
}

const GtmNoScript = (props: Props) => {
  const { gtmId } = props;

  if (!gtmId) {
    return null;
  }

  return (
    <noscript dangerouslySetInnerHTML={{
      __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
    }} />
  );
};

export default memo(GtmNoScript);
