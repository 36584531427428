import IconFont, { IconFontName } from '../../ui-kit/IconFont';
import Stack from '../../ui-kit/Stack/Stack';
import { useTheme } from '../../ui-kit/theme';
import { Typography } from '../../ui-kit/Typography';
import styled from '@emotion/styled';
import getStyles from './getStyles';
import { Theme } from '../../ui-kit/theme/types';

interface AccountNavigationItemProps {
  iconName: IconFontName;
  text: string;
  isActive?: boolean;
}

type StyledStackProps = {
  theme: Theme;
  isActive?: boolean;
}

const StyledStack = styled(Stack, {
  shouldForwardProp: prop => prop !== 'theme' && prop !== 'isActive',
})(
  ({ theme, isActive }: StyledStackProps) => {

    return getStyles(theme, isActive);
  },
);

const AccountNavigationItem = (props: AccountNavigationItemProps) => {
  const {iconName, text, isActive} = props;
  const theme = useTheme();

  return (
    <StyledStack theme={theme}
      isActive={isActive}
      dataMarker="AccountNavigationItem"
      alignItems="center"
      gap="v3"
    >
      <IconFont name={iconName} size='14px' color='gray300' className='AccountNavigationItem__icon'/>
      <Typography variant='text6' className='AccountNavigationItem__text'>{text}</Typography>
    </StyledStack>
  );
};

export default AccountNavigationItem;
