import { Typography } from "src/ui-kit/Typography";

export interface UnavailableLabelProps {
  onClick(): void;
  label: string;
  buttonLabel: string;
}

const RetailsUnavailableLabel = ({ onClick, label, buttonLabel }: UnavailableLabelProps) => {
  return (
    <Typography sbs={{ mb: '16px' }}>
      {label}
      {` `}
      <Typography
        element='span'
        color='primary'
        elementProps={{ onClick }}
        sbs={{ cursor: 'pointer' }}
        data-marker='Choose Another Delivery'
      >
        {buttonLabel}
      </Typography>
    </Typography>
  );
};

export default RetailsUnavailableLabel;
