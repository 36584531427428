import useProductRetailInfoLists from "../../hooks/useProductRetailInfoLists";
import RetailsInfo, { CommonRetailsInfoProps } from "../RetailsInfo/RetailsInfo";

export type ProductRetailsInfoProps = CommonRetailsInfoProps & { ean: string };

const ProductRetailsInfo = (props: ProductRetailsInfoProps) => {
  const { ean, ...commonProps } = props;
  const productRetailsInfo = useProductRetailInfoLists(ean);

  return <RetailsInfo {...commonProps} {...productRetailsInfo} />;
};

export default ProductRetailsInfo;
