/* istanbul ignore file */
import { css } from '@emotion/react';
import { Theme } from '../../ui-kit/theme/types';

const getStyles = (theme: Theme, isActive?: boolean) => {
  const colorStylesForInactive = `
    & .AccountNavigationItem__icon {
      color: ${theme.base.color.gray300};
      transition: 0.2s color ease-in-out;
    }
    & .AccountNavigationItem__text {
      color: ${theme.typography.color};
      transition: 0.2s color ease-in-out;
    }
    &:hover .AccountNavigationItem__icon,
    &:hover .AccountNavigationItem__text {
      color: ${theme.base.color.primary};
    }
  `;
  const colorStylesForActive = `
    & .AccountNavigationItem__icon,
    & .AccountNavigationItem__text {
      color: ${theme.base.color.primary};
    }
  `;

  return css(`
    padding: 9px 16px;
    ${isActive ? colorStylesForActive : colorStylesForInactive}
  `);
};

export default getStyles;
