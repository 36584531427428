import Head from 'next/head';
import { COUNTRY } from '../data/constants';

const openGraphBannersMap = {
  ua: {
    en: 'https://img4.zakaz.ua/banner_en.jpg',
    uk: 'https://img4.zakaz.ua/banner_uk.jpg',
    ru: 'https://img4.zakaz.ua/banner_ru.jpg',
  },
};

export interface OpenGraphMetaProps {
  lang: string;
  title: string;
  description: string;
}

const OpenGraphMeta = (props: OpenGraphMetaProps) => {
  const { lang, title, description } = props;
  const imgUrl = openGraphBannersMap[COUNTRY]?.[lang];
  const siteName = 'https://zakaz.ua/';

  if (!imgUrl) {
    return null;
  }

  return (
    <Head>
      <meta
        property='og:title'
        content={title}
        key='og:title'
      />
      <meta
        property='og:description'
        content={description}
        key='og:description' />
      <meta
        property="og:url"
        content={`${siteName}${lang}`}
        key='og:url'
      />
      <meta
        property='og:site_name'
        content={siteName}
        key='og:site_name'
      />
      <meta
        property="og:image"
        content={imgUrl}
        key='og:image'
      />
      <meta
        property="og:type"
        content="website"
        key='og:type'
      />
      <meta // needed to display the picture in the telegram
        name="twitter:card"
        content="summary_large_image"
      />
    </Head>
  );
};

export default OpenGraphMeta;
