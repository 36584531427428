import useLocalize from "src/services/localize/useLocalize";
import useRetailsInfoContext from "../../context/useRetailsInfoContext";
import RetailsInfo, { CommonRetailsInfoProps } from "../RetailsInfo/RetailsInfo";
import { UnavailableListLabels } from "../../types";

type Props = Omit<CommonRetailsInfoProps, 'unavailableListLabels'>

const UserRetailsInfo = (props: Props) => {
  const localize = useLocalize();
  const retailsInfoContext = useRetailsInfoContext();
  const unavailableListLabels: UnavailableListLabels = {
    label: `${localize('unavailable_retails_text_1')},`,
    button: localize('unavailable_retails_text_2'),
  };

  return <RetailsInfo
    {...props}
    {...retailsInfoContext}
    unavailableListLabels={unavailableListLabels}
  />;
};

export default UserRetailsInfo;
