import { CartItemCount } from "src/api/types";
import useUserProfile from "src/services/userProfile/useUserProfile";
import useSWR from "swr";
import { requestGetCartsItemCounts } from "../ajax/requestGetCartsItemCounts";

const emptyArray: CartItemCount[] = [];

const useCartItemsCounts = () => {
  const { userProfile } = useUserProfile();
  const userPhone = userProfile?.login.phone;
  const shouldFetch = !!userPhone;

  const { data, error } = useSWR<CartItemCount[]>(
    shouldFetch
      ? [userPhone]
      : null,
    requestGetCartsItemCounts);

  return {
    cartItemsCounts: data || emptyArray,
    cartItemsCountsAreFetching: shouldFetch ? !data && !error : false,
  };
};

export default useCartItemsCounts;
