import { fetcher } from "src/api/fetch";
import { CartItemCount } from "src/api/types";
import { getDataFromResponse } from "src/utils/api/getDataFromResponse";

export const requestGetCartsItemCounts = async () => {
  const response = await fetcher<CartItemCount[]>('/carts/item_counts', {
    credentials: 'include',
  });

  return getDataFromResponse(response, []);
};
