import { IS_UKRAINE, ZENDESK_KEY } from "src/data/constants";
import { RenderAfterInteract } from "../interact-watcher";
import Messengers from "./Messengers";

const MessengersWrapper = () => {
  if (IS_UKRAINE) {
    return (
      <RenderAfterInteract>
        <Messengers zendeskKey={ZENDESK_KEY} />
      </RenderAfterInteract>
    );
  }

  return null;
};

export default MessengersWrapper;
