import { createContext, ReactNode, useContext } from 'react';
import { Category } from 'src/api/types';
import themeOld, { ThemeOld } from '../styles/theme-old';
import useAuthService, { AuthService } from './auth/useAuthService';
import useCategoriesService from './categories/useCategoriesService';
import useLangService from './language/useLangService';
import { LocalizeFunc, useLocalizeService } from './localize/useLocalizeService';
import { ModalsApi } from './modals/types';
import useModalsService from './modals/useModalsService';
import useUserDeliveryService, { UserDeliveryService } from './userDelivery/useUserDeliveryService';
import { UserProfileService } from './userProfile/types';
import useUserProfileService from './userProfile/useUserProfileService';

export interface Services {
  themeOld: ThemeOld;
  language: string;
  localize: LocalizeFunc;
  userDelivery: UserDeliveryService;
  modals: ModalsApi;
  auth: AuthService;
  userProfile: UserProfileService;
  categories: Category[];
}

interface ServicesProviderProps {
  children: ReactNode;
  initLang: string;
  translations: any;
  initCategories: Category[];
}

export const ServicesContext = createContext<Services>(null as any);

export const useServices = () => useContext(ServicesContext);

export const ServicesProvider = ({
  children,
  initLang,
  translations,
  initCategories,
}: ServicesProviderProps) => {
  const language = useLangService(initLang);
  const localize = useLocalizeService({ translations });
  const auth = useAuthService(language);
  const userDelivery = useUserDeliveryService(language, auth.isLoggedIn);
  const modals = useModalsService();
  const userProfile = useUserProfileService(language, auth.isLoggedIn);
  const categories = useCategoriesService(initCategories);

  const contextValue = {
    themeOld,
    language,
    localize,
    userDelivery,
    modals,
    auth,
    userProfile,
    categories,
  };

  return (
    <ServicesContext.Provider value={contextValue}>
      {children}
    </ServicesContext.Provider>
  );
};
