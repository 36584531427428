import { UberProductChain } from "src/api/getProductDetailsData";
import { CartItemCount, QueryString, RetailChain, ScheduleDeliveryItem } from "src/api/types";
import { Delivery } from "src/modules/delivery/types";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import { RetailInfoLists } from "../types";
import getRetailInfo from "./getRetailInfo";

interface Params {
  deliveryType: Delivery['type'] | null;
  retailChains: RetailChain[];
  productChains: UberProductChain[];
  cartItemsCounts: CartItemCount[];
  scheduleDeliveryItems: ScheduleDeliveryItem[];
  deliveryAsQueryStringForLink: QueryString;
  language: string;
  localize: LocalizeFunc;
}

export const getRetailInfoListsForProduct = (params: Params) => {
  const {
    retailChains,
    productChains,
    deliveryType,
    cartItemsCounts,
    scheduleDeliveryItems,
    deliveryAsQueryStringForLink,
    language,
    localize,
  } = params;

  const retailChainsMap: {
    [key: string]: RetailChain;
  } = retailChains.reduce((acc, retailChain) => {
    acc[retailChain.id] = retailChain;
    return acc;
  }, {});

  const cartItemsCountsMap: {
    [key: string]: CartItemCount;
  } = cartItemsCounts.reduce((acc, item) => {
    acc[item.chain_id] = item;
    return acc;
  }, {});

  const findTimeWindow = (chainId: string) => {
    const scheduleItem = scheduleDeliveryItems.find(
      (item) => item.chain_id === chainId && !!item.schedule?.length,
    );
    return scheduleItem?.schedule[0].items[0] || null;
  };

  return productChains.reduce<RetailInfoLists>((acc, productChain) => {
    const chainId = productChain.chain_id;
    const retailChain = retailChainsMap[chainId];
    const isAvailable = productChain.available;

    if (!retailChain) {
      return acc;
    }

    const retailInfo = getRetailInfo({
      isAvailable,
      retailChain,
      cartItemsCount: cartItemsCountsMap[chainId]?.count,
      timeWindow: findTimeWindow(chainId),
      deliveryType,
      deliveryAsQueryStringForLink,
      language,
      localize,
    });

    if (isAvailable) {
      acc.available.push(retailInfo);
    } else {
      acc.unavailable.push(retailInfo);
    }

    return acc;
  }, { available: [], unavailable: [] });
};
